<template>
  <div class="contatain">
    <div class="flex-row">
      <el-select
        size="mini"
        style="width: 130px"
        class="m-r-10"
        v-model="params.searchKey"
      >
        <el-option
          v-for="item in searchKeys"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        ></el-option>
      </el-select>
      <el-input
        size="mini"
        style="width: 130px"
        class="m-r-10"
        v-model="params.searchValue"
      />
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-search"
        :loading="loading"
        @click="getDictTypeDataList"
        >搜索</el-button
      >
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="openDialog('addDictType')"
        >添加</el-button
      >
    </div>
    <div class="content-wrap">
      <el-card class="row2-card">
        <el-table
          border
          :data="dictTypeList"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          max-height="650"
          v-loading="loading"
        >
          <!-- <el-table-column prop="id" label="ID" width="180"> </el-table-column> -->
          <el-table-column
            prop="dictName"
            label="名称"
            width="230"
          ></el-table-column>
          <el-table-column label="类型" width="260">
            <template slot-scope="scope">
              <span @click="toDictData(scope.row.dictType)" class="link">{{ scope.row.dictType }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="230">
            <template slot-scope="scope">
              {{ transTime(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            width="230"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="openDialog('addDictType', scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteDictType(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="count"
          :page-size="params.limit"
          :current-page="params.page"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </el-card>
    </div>
    <add-dict-type
      ref="addDictType"
      @getDictTypeDataList="getDictTypeDataList"
      :editForm="editForm"
    />
  </div>
</template>
<script>
import { debounce, timestampToDate } from "@tools";
import addDictType from "./common/components/addDictType";
export default {
  components: {
    addDictType,
  },
  data() {
    this.searchKeys = [
      { value: "dict_name", label: "字典名称" },
      { value: "dict_type", label: "字典类型" },
    ];
    return {
      loading: false,
      dictTypeList: [],
      params: {
        limit: 10,
        page: 1,
        searchKey: "",
        searchValue: "",
      },
      count: 0,
      editForm: null,
    };
  },
  async created() {
    this.getDictTypeDataList();
  },
  mounted() {},
  methods: {
    getDictTypeDataList: debounce(async function () {
      this.loading = true;
      const data = await this.$api.systemConfig
        .getDictType(this.params)
        .finally(() => {
          this.loading = false;
        });
      this.dictTypeList = data.data;
      this.count = data.count
    }, 600),
    handleSizeChange(size) {
        this.params.limit = size
        this.getDictTypeDataList()
    },
    handlePageChange(page) {
        this.params.page = page
        this.getDictTypeDataList()
    },
    transTime(time) {
      return timestampToDate(time);
    },
    openDialog(ref, form) {
      if (form) {
        const { dictName, dictType, id, remark } = form;
        this.editForm = { dictName, dictType, id, remark };
      } else {
        this.editForm = null;
      }
      this.$refs[ref].showDialog = true;
    },
    deleteDictType(id) {
      this.$confirm("此操作将永久删除该字典类型, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.$api.systemConfig.deleteDictType(id)
          this.getDictTypeDataList()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toDictData(type) {
        this.$router.push({path: '/home/dictData', query: { type }})
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
</style>