<template>
  <el-dialog width="25%" :title="title" :visible.sync="showDialog">
    <el-form :model="form" label-width="80px" ref="aa">
      <el-form-item label="字典名称">
        <el-input v-model="form.dictName"></el-input>
      </el-form-item>
      <el-form-item label="字典类型">
        <el-input v-model="form.dictType"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="showDialog = false">取 消</el-button>
      <el-button
        size="mini"
        type="primary"
        @click="editDictType"
        :loading="loading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "addDictType",
  props: ["editForm"],
  mounted() {},
  data() {
    return {
      loading: false,
      showDialog: false,
      form: {
        id: "",
        dictType: "",
        dictName: "",
        remark: "",
      },
      title: "添加字典类型",
    };
  },
  methods: {
    async editDictType() {
      this.loading = true;
      await this.$api.systemConfig.editDictType(this.form).finally(() => {
        this.loading = false;
      });
      this.showDialog = false;
      this.$emit("getDictTypeDataList");
    },
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
      } else {
        this.editForm && Object.assign(this.form, this.editForm);
      }
    },
    editForm: {
      deep: true,
      handler(newVal) {
        this.title = `${newVal ? "编辑" : "添加"}字典类型`;
      },
    },
  },
};
</script>